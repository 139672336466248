
/*@import url('bootstrap.min.css');*/
@import url('font-awesome.min.css');
@import url('animate.css');
@import url('rsmenu-main.css');
@import url('hover-min.css');
@import url('magnific-popup.css');
@import url('owl.carousel.css');
@import url('time-circles.css');
@import url('slick.css');
@import url('style.css');
@import url('responsive.css');

.header-top-area
{
	text-align: center;

	a
	{
		color: #fff;
		font-size: 14px;
	}
}

.header-top-area .header-top-left ul li a,
.header-top-area .header-top-left ul li a:hover
{
	color: #fff;
}

.header-middle-area .rs-menu .nav-menu > li > a
{
	color: #fff !important;
}

.home-section
{
	padding: 60px 0;
	font-size: 16px;
	line-height: 32px;
}

.home-section.dark-section
{
	background: #111111;
	color: #fff;
}

.home-section h2.title-bg
{
	display: block;
	width: 80%;
	font-size: 32px;
	line-height: 48px;
	text-align: center;
	margin: 0 auto 60px auto;
}

.home-section h3
{
	display: block;
	color: #0d1ea0;
	text-align: center;
	font-size: 26px;
	line-height: 42px;
}

.home-section.dark-section h2.title-bg,
.home-section.dark-section h3
{
	color: #fff;
}

.home-section img.border-img
{
	border: 5px solid #0D1EA0;
	box-shadow: 10px 10px 0 #111;
	padding: 5px;

	@media(max-width:991px)
	{
		margin: 0 0 30px 0;
	}
}

.home-section ul
{
	list-style: initial;
	padding-left: 18px;
}

.image-popup
{
	display: block;
	cursor: pointer;
}

.header-middle-area
{
	.logo
	{
		padding-top: 0;

		a
		{
			display: block;
			padding: 5px 0;

			img
			{
				width: auto;
				height: 70px;
			}

			@media(max-width:768px)
			{
				padding-top: 15px;
			}
		}
	}

	@media(max-width:991px)
	{
		.logo
		{
			padding: 0;
		}
	}
}

.menu-sticky.sticky .logo a
{
	padding: 15px 0;
}

.menu-sticky.sticky .logo img
{
	height: 50px;
	width: auto;
	max-width: none;
}

.home-map
{
	border: 5px solid #0D1EA0;
	box-shadow: 10px 10px 0 #111;
	padding: 5px;
	margin-bottom: 15px;
}

.association-quotes
{
	display: flex;
	justify-content: center;
	margin-bottom: 45px;

	.association-quote
	{
		border: 5px solid #0D1EA0;
		box-shadow: 10px 10px 0 #fff;
		padding: 30px 60px;
		margin: 0 15px;
		text-align: center;
		cursor: default;

		&:hover
		{
			background: #0D1EA0;
			color: #fff;
		}

		span
		{
			display: block;
			margin-bottom: 45px;
			padding-bottom: 15px;
			border-bottom: 1px solid #fff;
		}

		strong
		{
			font-size: 32px;
		}
	}

	@media(max-width:991px)
	{
		flex-direction: column;

		.association-quote
		{
			margin: 0 0 45px 0;

			&:last-child
			{
				margin-bottom: 0;
			}
		}
	}
}

.kit-list
{
	display: flex;
	list-style: none !important;
	margin: 0;
	padding: 0 !important;

	> li
	{
		flex: 1;
		margin: 0 15px 15px 0;
		padding: 10px 15px;
		background: #fff;
		border-bottom: 5px solid #0d1ea0;
		color: #111;
		text-align: center;

		&:last-child
		{
			margin-right: 0;
		}
	}

	@media(max-width:991px)
	{
		flex-direction: column;

		> li
		{
			margin: 0 0 15px 0;

			&:last-child
			{
				margin-bottom: 0;
			}
		}
	}
}

.rs-menu-toggle
{
	&:active, &:hover
	{
		color: #fff;
	}

	@media(max-width:991px)
	{
		background: none;
		border: 0;
		text-align: right;
		font-size: 24px;
		padding: 27px 0;

		i
		{
			margin-right: 15px;
		}
	}

	@media(max-width:768px)
	{
		padding: 20px 0;
		margin-top: 10px;
		text-align: center;
		border-top: 1px solid #0d1ea0;
	}
}

.header-middle-area
{
	.rs-menu > ul > li > a
	{
		@media(max-width:1199px)
		{
			padding: 28px 6px;
		}

		@media(max-width:768px)
		{
			text-align: center;
		}
	}
}

.subscribe-form
{
	margin-top: 45px;
	padding: 45px;
	background: #0d1ea0;
	box-shadow: 10px 10px 0 #fff;

	form
	{
		margin-top: 45px;

		.form-control
		{
			border-radius: 0;
			height: 48px;
		}
	}

	.btn-send
	{
		color: #fff;
		background-color: #111;
		text-transform: uppercase;
		margin-top: 30px;
		border: none;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-weight: 500;
		padding: 0 50px;
	}
}

.result_message .alert-success
{
	background: none;
	text-align: center;
	padding: 30px;
	color: #00bf0f;
	font-size: 22px;
	line-height: 30px;
	font-weight: bold;
}

.single-gallery
{
	display: block;
	width: 100%;
	height: 280px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}