
.time_circles {
    position: relative;
    width: 100%;
    height: 100%;
	left: -14px;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
    position: absolute;
    text-align: left;
}

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
}

/**
 *	Time numbers, ie: 12
 **/
.time_circles > div > span {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    text-align: center;
    line-height: 1;
}